import { defineComponent } from "vue";
import { Line as LineChartGenerator } from "vue-chartjs";
export default defineComponent({
  name: "LineChart",
  extends: LineChartGenerator,
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
  watch: {
    chartData(newData) {
      if (newData && newData.labels && newData.datasets) {
        this.$data._chart.data.labels = newData.labels;
        this.$data._chart.data.datasets = newData.datasets;
        this.$data._chart.update();
      }
    }
  }
});
