<template>
<div class="row">
      <div class="col-lg-12" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h3>Welcome To Lotti Admin Dashboard</h3>
          </template>

        </card>
      </div>
  </div>
</template>
<script>
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import * as chartConfigs from '@/components/Charts/config';
  import TaskList from './Dashboard/TaskList';
  import UserTable from './Dashboard/UserTable';
  import config from '@/config';

  export default {
    components: {
      LineChart,
      BarChart,
      TaskList,
      UserTable
    },
    data() {
      return {
        // bigLineChart: {
        //   allData: [
        //     [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
        //     [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
        //     [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130]
        //   ],
        //   activeIndex: 0,
        //   chartData: {
        //     datasets: [{ }],
        //     labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
        //   },
        //   extraOptions: chartConfigs.purpleChartOptions,
        //   gradientColors: config.colors.primaryGradient,
        //   gradientStops: [1, 0.4, 0],
        //   categories: []
        // },
        // purpleLineChart: {
        //   extraOptions: chartConfigs.purpleChartOptions,
        //   chartData: {
        //     labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
        //     datasets: [{
        //       label: "Data",
        //       fill: true,
        //       borderColor: config.colors.primary,
        //       borderWidth: 2,
        //       borderDash: [],
        //       borderDashOffset: 0.0,
        //       pointBackgroundColor: config.colors.primary,
        //       pointBorderColor: 'rgba(255,255,255,0)',
        //       pointHoverBackgroundColor: config.colors.primary,
        //       pointBorderWidth: 20,
        //       pointHoverRadius: 4,
        //       pointHoverBorderWidth: 15,
        //       pointRadius: 4,
        //       data: [80, 100, 70, 80, 120, 80],
        //     }]
        //   },
        //   gradientColors: config.colors.primaryGradient,
        //   gradientStops: [1, 0.2, 0],
        // },
        // greenLineChart: {
        //   extraOptions: chartConfigs.greenChartOptions,
        //   chartData: {
        //     labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV'],
        //     datasets: [{
        //       label: "My First dataset",
        //       fill: true,
        //       borderColor: config.colors.danger,
        //       borderWidth: 2,
        //       borderDash: [],
        //       borderDashOffset: 0.0,
        //       pointBackgroundColor: config.colors.danger,
        //       pointBorderColor: 'rgba(255,255,255,0)',
        //       pointHoverBackgroundColor: config.colors.danger,
        //       pointBorderWidth: 20,
        //       pointHoverRadius: 4,
        //       pointHoverBorderWidth: 15,
        //       pointRadius: 4,
        //       data: [90, 27, 60, 12, 80],
        //     }]
        //   },
        //   gradientColors: ['rgba(66,134,121,0.15)', 'rgba(66,134,121,0.0)', 'rgba(66,134,121,0)'],
        //   gradientStops: [1, 0.4, 0],
        // },
        // blueBarChart: {
        //   extraOptions: chartConfigs.barChartOptions,
        //   chartData: {
        //     labels: ['USA', 'GER', 'AUS', 'UK', 'RO', 'BR'],
        //     datasets: [{
        //       label: "Countries",
        //       fill: true,
        //       borderColor: config.colors.info,
        //       borderWidth: 2,
        //       borderDash: [],
        //       borderDashOffset: 0.0,
        //       data: [53, 20, 10, 80, 100, 45],
        //     }]
        //   },
        //   gradientColors: config.colors.primaryGradient,
        //   gradientStops: [1, 0.4, 0],
        // }
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      // bigLineChartCategories() {
      //   return this.$t('dashboard.chartCategories');
      // }
    },
    methods: {
      // initBigChart(index) {
      //   let chartData = {
      //     datasets: [{
      //       fill: true,
      //       borderColor: config.colors.primary,
      //       borderWidth: 2,
      //       borderDash: [],
      //       borderDashOffset: 0.0,
      //       pointBackgroundColor: config.colors.primary,
      //       pointBorderColor: 'rgba(255,255,255,0)',
      //       pointHoverBackgroundColor: config.colors.primary,
      //       pointBorderWidth: 20,
      //       pointHoverRadius: 4,
      //       pointHoverBorderWidth: 15,
      //       pointRadius: 4,
      //       data: this.bigLineChart.allData[index]
      //     }],
      //     labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
      //   }
      //   this.$refs.bigChart.updateGradients(chartData);
      //   this.bigLineChart.chartData = chartData;
      //   this.bigLineChart.activeIndex = index;
      // }
    },
    mounted() {
      // this.i18n = this.$i18n;
      // if (this.enableRTL) {
      //   this.i18n.locale = 'ar';
      //   this.$rtl.enableRTL();
      // }
      // this.initBigChart(0);
    },
    beforeDestroy() {
      // if (this.$rtl.isRTL) {
      //   this.i18n.locale = 'en';
      //   this.$rtl.disableRTL();
      // }
    }
  };
</script>
<style>
.card  {
      background: transparent;
    color: #ffff
}
</style>
